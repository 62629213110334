import { render, staticRenderFns } from "./index.vue?vue&type=template&id=dd831c1c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=dd831c1c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd831c1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Carousel: require('/Users/wu67/Projects/Au_front/components/Carousel.vue').default,SubNav: require('/Users/wu67/Projects/Au_front/components/SubNav.vue').default,PcTitle: require('/Users/wu67/Projects/Au_front/components/PcTitle.vue').default,Card: require('/Users/wu67/Projects/Au_front/components/Card.vue').default,RowCard: require('/Users/wu67/Projects/Au_front/components/RowCard.vue').default})
